export const defaultFilter = {
  contractorChecked: false,
  contractor: '',
  codeIdChecked: false,
  codeId: '',
  nameChecked: false,
  name: '',
  emailChecked: false,
  email: '',
  idCardNoChecked: false,
  idCardNo: '',
  dateChecked: false,
  startDate: '',
  endDate: '',
  subStatus: [],
  receivedDateChecked: false,
  receivedStartDate: '',
  receivedEndDate: '',
  agentCodeChecked: false,
  agentCode: '',
  effectivedDateChecked: false,
  effectivedStartDate: '',
  effectivedEndDate: '',
}
