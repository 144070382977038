export const filterItems = [
  {
    label: 'ผู้รับงาน',
    nameCheckBox: 'contractorChecked',
    nameInput: 'contractor',
    type: 'text',
  },
  {
    label: 'หมายเลข',
    nameCheckBox: 'codeIdChecked',
    nameInput: 'codeId',
    type: 'text',
  },
  {
    label: 'ชื่อผู้สมัคร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    type: 'text',
  },
  {
    label: 'อีเมล',
    nameCheckBox: 'emailChecked',
    nameInput: 'email',
    type: 'text',
  },
  {
    label: 'เลขบัตรประชาชน',
    nameCheckBox: 'idCardNoChecked',
    nameInput: 'idCardNo',
    type: 'text',
  },
  {
    label: 'วันที่สมัคร',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    dateStateStart: 'startDate',
    dateStateFinish: 'endDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'วันที่รับเอกสาร',
    nameCheckBox: 'receivedDateChecked',
    nameInput: 'date',
    dateStateStart: 'receivedStartDate',
    dateStateFinish: 'receivedEndDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'รหัสตัวแทน',
    nameCheckBox: 'agentCodeChecked',
    nameInput: 'agentCode',
    type: 'text',
  },
  {
    label: 'วันที่ในสัญญา',
    nameCheckBox: 'effectivedDateChecked',
    nameInput: 'date',
    dateStateStart: 'effectivedStartDate',
    dateStateFinish: 'effectivedEndDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
]
