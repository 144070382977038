import { AppDispatch } from '../../../redux/store'
import { setNotificationFilterType } from '../slices'
import { FilterType } from '../slices/model'
import { loadNotification } from './loadNotification'

export const changeFilterType =
  (type: FilterType, limit: number) => async (dispatch: AppDispatch) => {
    dispatch(setNotificationFilterType(type))
    await dispatch(loadNotification({ page: 1, tab: type, limit: limit }))
  }
