import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Event from '@mui/icons-material/Event'
import DateRangePicker from './DateRangePicker'
import { getDisabledDates } from './events'
import { StyledDateInput, StyledDateRangeContainer } from './styled'
import { TypographyVariant } from '@mui/material'
import { displayDateTimeV2 } from '../../utils/convertFormatDateTime'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { setMinDate } from './slices'
import { dispatch } from '../../redux/store'

dayjs.extend(utc)
dayjs.extend(timezone)

interface DateRangeInputProps {
  labelText?: string
  labelVariant?: TypographyVariant
  disabled?: boolean
  required?: boolean
  dateState: { startDate: Date; endDate: Date; display?: boolean }
  disableDates?: { startDate: Date; finishDate: Date }[]
  placeholder?: string
  minRange?: number | null
  maxDate?: Date
  minDate?: Date
  onChange: (dateState: {
    startDate?: Date
    endDate?: Date
    display?: boolean
  }) => void
  textError?: string
  showTextError?: boolean
}

const DateRangeInput: React.FC<DateRangeInputProps> = (props) => {
  const {
    labelText,
    labelVariant = 'body2',
    disabled = false,
    required = false,
    dateState,
    disableDates = [],
    placeholder = 'ค้นหา',
    minRange = null,
    maxDate,
    minDate,
    onChange,
    textError,
    showTextError = true,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState([
    {
      ...dateState,
      startDate: dayjs(dateState.startDate).tz('Asia/Bangkok').toDate(),
      endDate: dayjs(dateState.endDate).tz('Asia/Bangkok').toDate(),
      display: dateState.display ?? false,
    },
  ])
  const [disableDateArr, setDisableDateArr] = useState<Date[]>([])
  const isError = showTextError && !_.isEmpty(textError)

  useEffect(() => {
    const allDisabledDates = getDisabledDates(disableDates)
    setDisableDateArr(allDisabledDates)
  }, [])

  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node))
        setIsOpen(false)
    }
    document.addEventListener('click', handleClickAway, true)
    return () => {
      document.removeEventListener('click', handleClickAway, true)
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          variant={labelVariant}
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <StyledDateRangeContainer>
        <StyledDateInput
          data-testid="date-input"
          disabled={disabled}
          isError={isError}
          onClick={() => !disabled && setIsOpen((e) => !e)}
        >
          {state[0].display ? (
            <Typography color={disabled ? 'text.disabled' : 'text.primary'}>
              {displayDateTimeV2(state[0].startDate)} -{' '}
              {displayDateTimeV2(state[0].endDate)}
            </Typography>
          ) : (
            <Typography color="text.lightGray">{placeholder}</Typography>
          )}
          <IconButton color="primary" disabled={disabled}>
            <Event />
          </IconButton>
        </StyledDateInput>
        <div ref={ref}>
          {isOpen && (
            <DateRangePicker
              maxDate={maxDate}
              minDate={minDate}
              selectedDates={state}
              disableDates={disableDateArr}
              onChange={(item) => {
                const newDateState = {
                  ...item.selection,
                  startDate: dayjs(item.selection.startDate)
                    .tz('Asia/Bangkok')
                    .toDate(),
                  endDate: dayjs(item.selection.endDate)
                    .tz('Asia/Bangkok')
                    .toDate(),
                  display: true,
                }
                setState([newDateState])

                if (onChange) onChange(newDateState)

                if (
                  displayDateTimeV2(item.selection.startDate) !==
                  displayDateTimeV2(item.selection.endDate)
                ) {
                  setIsOpen(false)
                  if (!_.isNull(minRange)) dispatch(setMinDate())
                } else {
                  if (!_.isNull(minRange)) {
                    const minNumber = Number(minRange)
                    dispatch(
                      setMinDate(
                        dayjs(item.selection.startDate)
                          .subtract(minNumber, 'day')
                          .toDate()
                      )
                    )
                  }
                }
              }}
            />
          )}
        </div>
      </StyledDateRangeContainer>
      {isError && (
        <Typography variant="body2" color="error">
          {textError}
        </Typography>
      )}
    </Box>
  )
}

export default DateRangeInput
