import { store } from '../../../../../../redux/store'
import _ from 'lodash'
import dayjs from 'dayjs'
import { getQueryParams } from '../../../../../../utils/getQueryParams'
import { DC_STATUS } from '../../../../../../constants/dc-admin'
import {
  setHandleChange,
  // setSelected,
} from '../../../../../../components/Table/redux'

export const mutateFilterPropToBody = () => {
  const { filterState } = store.getState().table
  const status = getQueryParams('status')
  const menu = _.find(DC_STATUS, { name: status })

  const startDate = formatDate(_.get(filterState, 'startDate', null))
  const endDate = formatDate(_.get(filterState, 'endDate', null))
  const receivedStartDate = formatDate(
    _.get(filterState, 'receivedStartDate', null)
  )
  const receivedEndDate = formatDate(
    _.get(filterState, 'receivedEndDate', null)
  )
  const effectivedStartDate = formatDate(
    _.get(filterState, 'effectivedStartDate', null)
  )
  const effectivedEndDate = formatDate(
    _.get(filterState, 'effectivedEndDate', null)
  )

  const paramSubStatus = getQueryParams('subStatus')
  let subStatus: string[] = []
  if (!_.isEmpty(paramSubStatus)) {
    const arrSubStatus = paramSubStatus.split(',')
    subStatus = _.size(arrSubStatus) > 0 ? arrSubStatus : []
    const newFilter = { ...filterState, subStatus: subStatus }
    store.dispatch(
      setHandleChange({
        key: 'filterState',
        value: newFilter,
      })
    )
  }
  // store.dispatch(setSelected([]))
  return {
    codeId: filterState?.codeIdChecked ? filterState?.codeId : '',
    name: filterState?.nameChecked ? filterState?.name : '',
    contractor: filterState?.contractorChecked ? filterState?.contractor : '',
    email: filterState?.emailChecked ? filterState?.email : '',
    idCardNo: filterState?.idCardNoChecked ? filterState?.idCardNo : '',
    registerStartDate: filterState?.registerDateChecked ? startDate : '',
    registerEndDate: filterState?.registerDateChecked ? endDate : '',
    status: _.size(menu?.status) > 0 ? menu?.status : [],
    subStatus: subStatus,
    receivedStartDate: filterState?.receivedDateChecked
      ? receivedStartDate
      : '',
    receivedEndDate: filterState?.receivedDateChecked ? receivedEndDate : '',
    agentCode: filterState?.agentCodeChecked ? filterState?.agentCode : '',
    effectivedStartDate: filterState?.effectivedDateChecked
      ? effectivedStartDate
      : '',
    effectivedEndDate: filterState?.effectivedDateChecked
      ? effectivedEndDate
      : '',
  }
}

const formatDate = (value: any) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value
  if (dateValue && dateValue !== '')
    dateValue = dayjs(new Date(dateValue)).format(format)
  else dateValue = null
  return dateValue
}
